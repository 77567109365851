import { Route } from '@angular/router';
import { ROUTE_PARAMS_TOKENS, ROUTE_TOKENS } from '@frr/shared/app-config';

export const appRoutes: Route[] = [
  {
    loadComponent: () =>
      import('@frr/pages/about').then((c) => c.AboutComponent),
    path: ROUTE_TOKENS.ABOUT,
  },
  {
    loadComponent: () =>
      import('@frr/pages/accommodation').then((c) => c.AccommodationComponent),
    path: ROUTE_TOKENS.ACCOMMODATION,
  },
  {
    loadComponent: () =>
      import('@frr/pages/contacts').then((c) => c.ContactsComponent),
    path: ROUTE_TOKENS.CONTACTS,
  },
  {
    loadComponent: () =>
      import('@frr/pages/exhibition').then((c) => c.ExhibitionComponent),
    path: ROUTE_TOKENS.EXHIBITION,
  },
  {
    loadComponent: () =>
      import('@frr/pages/expert-board').then((c) => c.ExpertBoardComponent),
    path: ROUTE_TOKENS.EXPERT_BOARD,
  },
  {
    loadComponent: () =>
      import('@frr/pages/history').then((c) => c.HistoryComponent),
    path: ROUTE_TOKENS.HISTORY,
  },
  {
    loadComponent: () =>
      import('@frr/pages/history').then((c) => c.History2024Component),
    path: ROUTE_TOKENS.HISTORY2024,
  },
  {
    loadComponent: () =>
      import('@frr/pages/history').then((c) => c.History2023Component),
    path: ROUTE_TOKENS.HISTORY2023,
  },
  {
    loadComponent: () =>
      import('@frr/pages/history').then((c) => c.History2022Component),
    path: ROUTE_TOKENS.HISTORY2022,
  },
  {
    loadComponent: () =>
      import('@frr/pages/history').then((c) => c.History2021Component),
    path: ROUTE_TOKENS.HISTORY2021,
  },
  {
    loadComponent: () =>
      import('@frr/pages/history-detailed').then(
        (c) => c.HistoryDetailedComponent,
      ),
    path: `${ROUTE_TOKENS.HISTORY}/:${ROUTE_PARAMS_TOKENS.HISTORY_ID}`,
  },
  {
    loadComponent: () =>
      import('@frr/pages/location').then((c) => c.LocationComponent),
    path: ROUTE_TOKENS.LOCATION,
  },
  {
    loadComponent: () =>
      import('@frr/pages/materials').then((c) => c.MaterialsComponent),
    path: ROUTE_TOKENS.MATERIALS,
  },
  {
    loadComponent: () => import('@frr/pages/main').then((c) => c.MainComponent),
    path: '',
  },
  {
    loadComponent: () =>
      import('@frr/pages/organizing-committee').then(
        (c) => c.OrganizingCommitteeComponent,
      ),
    path: ROUTE_TOKENS.ORGANIZING_COMMITTEE,
  },
  {
    loadComponent: () =>
      import('@frr/pages/participation').then((c) => c.ParticipationComponent),
    path: ROUTE_TOKENS.PARTICIPATION,
  },
  {
    loadComponent: () =>
      import('@frr/pages/participation-terms').then(
        (c) => c.ParticipationTermsComponent,
      ),
    path: ROUTE_TOKENS.PARTICIPATION_TERMS,
  },
  {
    loadComponent: () =>
      import('@frr/pages/participation-for-speaker').then(
        (c) => c.ParticipationForSpeakerComponent,
      ),
    path: ROUTE_TOKENS.PARTICIPATION_FOR_SPEAKER,
  },
  {
    loadComponent: () =>
      import('@frr/pages/participation-for-exponent').then(
        (c) => c.ParticipationForExponentComponent,
      ),
    path: ROUTE_TOKENS.PARTICIPATION_FOR_EXPONENT,
  },
  {
    loadComponent: () =>
      import('@frr/pages/participation-terms').then(
        (c) => c.ParticipationTermsComponent,
      ),
    path: ROUTE_TOKENS.PARTICIPATION_TERMS,
  },
  {
    loadComponent: () =>
      import('@frr/pages/partners').then((c) => c.PartnersComponent),
    path: ROUTE_TOKENS.PARTNERS,
  },
  {
    loadComponent: () =>
      import('@frr/pages/program/program').then((c) => c.ProgramComponent),
    path: ROUTE_TOKENS.PROGRAM,
  },
  {
    loadComponent: () =>
      import('@frr/pages/program/program').then((c) => c.ProgramComponent),
    path: `${ROUTE_TOKENS.PROGRAM}`,
  },
  {
    loadComponent: () =>
      import('@frr/pages/program-page').then((c) => c.ProgramPageComponent),
    path: `${ROUTE_TOKENS.PROGRAM}/:${ROUTE_PARAMS_TOKENS.EVENT_ID}`,
  },
  {
    loadComponent: () =>
      import('@frr/pages/special-project-detailed').then(
        (c) => c.SpecialProjectDetailedComponent,
      ),
    path: `${ROUTE_TOKENS.SPECIAL_PROJECTS}/:${ROUTE_PARAMS_TOKENS.SPECIAL_PROJECT_ID}`,
  },
  {
    loadComponent: () =>
      import('@frr/pages/special-projects').then(
        (c) => c.SpecialProjectsComponent,
      ),
    path: ROUTE_TOKENS.SPECIAL_PROJECTS,
  },
  {
    loadComponent: () =>
      import('@frr/pages/news-detailed').then((m) => m.NewsDetailedComponent),
    path: `${ROUTE_TOKENS.NEWS}/:${ROUTE_PARAMS_TOKENS.NEWS_ID}`,
  },
  {
    loadComponent: () => import('@frr/pages/news').then((m) => m.NewsComponent),
    path: ROUTE_TOKENS.NEWS,
  },
  {
    loadComponent: () =>
      import('@frr/pages/speakers').then((m) => m.SpeakersComponent),
    path: ROUTE_TOKENS.SPEAKERS,
  },
  { path: '**', redirectTo: '' },
];
